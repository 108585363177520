// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/accessControlProvider.ts"
);
import.meta.hot.lastModified = "1710414077960.0054";
}
// REMIX HMR END

import { AccessControlProvider, CanParams } from '@refinedev/core'
import { CanResponse } from '@refinedev/core/dist/interfaces/bindings/access-control'
import { hasAccess } from '~/services/accessControl'

export const accessControlProvider: AccessControlProvider = {
  can: async ({ resource, action, params }: CanParams): Promise<CanResponse> => {

    return { can: await hasAccess({ resource, action, params }) }
  },
  options: {
    buttons: {
      enableAccessControl: true,
      hideIfUnauthorized: false,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      cacheTime: 10000
    },
  },
}
