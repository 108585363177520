// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/createAuthProvider.ts"
);
import.meta.hot.lastModified = "1712867841730.761";
}
// REMIX HMR END

import type { AuthBindings } from '@refinedev/core'
import { callAuthProvider } from '~/services/callAuthProvider.client'
import axios from 'axios'
import { IUser } from '~/interfaces/user'

interface AuthResponse {
  success: boolean
  message: string
}

interface AuthProviderRequest {
  methodName: keyof typeof createAuthProvider
  parameters: any
}

const validateProfile = (data: any): data is IUser => {
  return 'id' in data && 'first_name' in data && 'email' in data
}

export const createAuthProvider = (initialProfile: IUser | null, initialPermissions: string[]): AuthBindings => {
  let profile = initialProfile
  let permissions = initialPermissions


  const initProfile = async () => {
    if (!profile) {
      const profileData = await callAuthProvider<AuthProviderRequest>('getIdentity', {})
      if (!profileData.error && profileData.data && validateProfile(profileData.data)) {
        profile = profileData.data
      } else {
        profile = null
      }
    }
  }

  const initPermissions = async () => {
    if (!permissions.length) {
      const permissiontsData = await callAuthProvider<AuthProviderRequest>('getPermissions', {})
      permissions = !permissiontsData.error && Array.isArray(permissiontsData.data) ? permissiontsData.data : []
    }
  }

  return {
    login: async ({ email, password }) => {
      try {
        const response = await axios.post(
          '/action/login',
          new URLSearchParams({
            email,
            password,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
        initProfile();
        initPermissions();

        return response.data
      } catch (error) {
        return {
          success: false,
          error: {
            message: 'Login failed',
            name: 'An error occurred while executing the request',
          },
        }
      }
    },
    logout: async () => {
      profile = null
      permissions = []
      return {
        success: true,
        redirectTo: '/logout',
      }
    },
    onError: async (error) => {
      console.error(error)
      if (error.response?.status === 401) {
        return {
          logout: true,
        }
      }

      return { error }
    },
    check: async () => {
      return {
        authenticated: !!profile,
      }
    },
    getPermissions: async () => {
      await initPermissions()
      return permissions
    },
    getIdentity: async () => {
      await initProfile();
      return profile
    },
  }
}
