// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/callDataProvider.client.ts"
);
import.meta.hot.lastModified = "1712000285890.2896";
}
// REMIX HMR END

import axios from 'axios'
import { ApiResponse } from '~/interfaces/api'

export const callApiDataProvider = async <TData>(methodName: string, parameters?: any): Promise<ApiResponse<TData>> => {
  try {
    const response = await axios.post('/action/data', {
      methodName,
      parameters,
    })

    if (response.data.error) {
      return { error: response.data.error }
    }

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return { error: error.response.data.error || 'An error occurred while executing the request' }
    }
    return { error: 'An error occurred while executing the request' }
  }
}
