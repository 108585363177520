// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/dataProvider.client.ts"
);
import.meta.hot.lastModified = "1712000285890.2896";
}
// REMIX HMR END

import {
  DataProvider,
  BaseRecord,
  GetListParams,
  GetOneParams,
  UpdateParams,
  DeleteOneParams,
  CreateParams,
} from '@refinedev/core'
import { callApiDataProvider } from '~/services/callDataProvider.client'
import { ApiResponse } from '~/interfaces/api'

interface CustomRecord extends BaseRecord {
  name?: string
}

export const dataProvider: DataProvider = {
  getList: async <TData extends BaseRecord = CustomRecord>(params: GetListParams) => {
    const result = await callApiDataProvider<ApiResponse<TData[]>>('getList', params)

    if (!result.error && result.data) {
      return {
        data: result.data as TData[],
        total: result.total ?? 0,
      }
    } else {
      return { data: [], total: 0 }
    }
  },

  getMany: async <TData extends BaseRecord = CustomRecord>() => {
    return Promise.resolve({ data: [] })
  },

  create: async <TData extends BaseRecord = CustomRecord, TVariables = {}>(params: CreateParams<TVariables>) => {
    const result = await callApiDataProvider<ApiResponse<TData>>('create', params)
    if (!result.error && result.data) {
      return {
        data: result.data as TData,
      }
    }
    if (result.error) {
      throw new Error(result.error)
    }
    throw new Error('Create failed')
  },

  createMany: async <TData extends BaseRecord = CustomRecord>() => {
    return Promise.resolve({ data: [] })
  },

  update: async <TData extends BaseRecord = CustomRecord, TVariables = {}>(params: UpdateParams<TVariables>) => {
    const result = await callApiDataProvider<ApiResponse<TData>>('update', params)
    if (!result.error && result.data) {
      return {
        data: result.data as TData,
      }
    }
    if (result.error) {
      throw new Error(result.error)
    }
    throw new Error('Update failed')
  },

  updateMany: async <TData extends BaseRecord = CustomRecord>() => {
    return Promise.resolve({ data: [{}] as TData[] })
  },

  getOne: async <TData extends BaseRecord = CustomRecord>(params: GetOneParams) => {
    const result = await callApiDataProvider<ApiResponse<TData>>('getOne', params)
    if (!result.error && result.data) {
      return {
        data: result.data as TData,
      }
    }
    return { data: {} as TData }
  },

  deleteOne: async <TData extends BaseRecord = BaseRecord, TVariables = {}>(params: DeleteOneParams<TVariables>) => {
    const result = await callApiDataProvider<ApiResponse<TData>>('deleteOne', params)
    if (!result.error && result.data) {
      return {
        data: result.data as TData,
      }
    }
    throw new Error('Delete failed')
  },

  deleteMany: async <TData extends BaseRecord = CustomRecord>() => {
    return Promise.resolve({ data: [{}] as TData[] })
  },

  getApiUrl: () => {
    throw Error('Not implemented on data provider.')
  },

  custom: async <TData extends BaseRecord = CustomRecord>() => {
    return Promise.resolve({ data: {} as TData })
  },
}
