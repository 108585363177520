// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/resolverDataProvider.ts"
);
import.meta.hot.lastModified = "1709839729654.9177";
}
// REMIX HMR END

import { dataProvider as dataProviderClient } from "~/providers/dataProvider.client";
import { dataProvider as dataProviderServer } from "~/providers/dataProvider.server";

const isServer = typeof window === 'undefined';
const dataProvider = isServer ? dataProviderServer : dataProviderClient;

export default dataProvider;
