// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { json } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { useNotificationProvider } from '@refinedev/antd';
import { Refine } from '@refinedev/core';
import { DevtoolsPanel, DevtoolsProvider } from '@refinedev/devtools';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import routerProvider, { UnsavedChangesNotifier } from '@refinedev/remix-router';
import { ColorModeContextProvider } from '@contexts';
import resetStyle from '@refinedev/antd/dist/reset.css';
import dataProvider from '~/providers/resolverDataProvider';
import { App as AntdApp } from 'antd';
import { authProvider } from '~/providers/authProvider.server';
import { createAuthProvider } from '~/providers/createAuthProvider';
import { accessControlProvider } from '~/providers/accessControlProvider';
export const meta = () => [{
  charset: 'utf-8',
  title: 'New Remix + Refine App',
  viewport: 'width=device-width,initial-scale=1'
}];
export default function App() {
  _s();
  const {
    profile,
    to,
    permissions
  } = useLoaderData();
  const clientAuthProvider = createAuthProvider(profile, permissions);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <AntdApp>
              <DevtoolsProvider>
                <Refine routerProvider={routerProvider} dataProvider={dataProvider} notificationProvider={useNotificationProvider} authProvider={clientAuthProvider} accessControlProvider={accessControlProvider} resources={[{
                name: 'Projects'
              }, {
                name: 'Project',
                list: '/project',
                edit: '/project/edit/:id',
                create: '/project/create',
                meta: {
                  parent: 'Projects',
                  label: 'Projects list'
                }
              }, {
                name: 'Project Calendar',
                list: '/project-calendar',
                edit: '/project-calendar/edit/:id',
                create: '/project-calendar/create',
                meta: {
                  label: 'Project Calendars',
                  parent: 'Projects'
                }
              }, {
                name: 'Project Timeline',
                list: '/project-timeline',
                edit: '/project-timeline/edit/:id',
                create: '/project-timeline/create',
                meta: {
                  label: 'Project Timelines',
                  parent: 'Projects'
                }
              }, {
                name: 'Project Payment Schedule',
                list: '/project-payment-schedule',
                edit: '/project-payment-schedule/edit/:id',
                create: '/project-payment-schedule/create',
                meta: {
                  label: 'Project Payment Schedules',
                  parent: 'Projects'
                }
              }, {
                name: 'Project Status',
                list: '/project-status',
                edit: '/project-status/edit/:id',
                create: '/project-status/create',
                meta: {
                  label: 'Project Status',
                  parent: 'Projects',
                  hide: true
                }
              }, {
                name: 'Project Group',
                list: '/project-group',
                edit: '/project-group/edit/:id',
                create: '/project-group/create',
                meta: {
                  label: 'Project Groups',
                  parent: 'Projects',
                  hide: true
                }
              }, {
                name: 'Project Group Type',
                list: '/project-group-type',
                edit: '/project-group-type/edit/:id',
                create: '/project-group-type/create',
                meta: {
                  label: 'Project Group Types',
                  parent: 'Projects',
                  hide: true
                }
              }, {
                name: 'Project',
                list: '/project',
                edit: '/project/edit/:id',
                create: '/project/create',
                meta: {
                  parent: 'Projects'
                }
              }, {
                name: 'Employees'
              }, {
                name: 'Employee',
                list: '/employee',
                edit: '/employee/edit/:id',
                create: '/employee/create',
                meta: {
                  label: 'Employees list',
                  parent: 'Employees'
                }
              }, {
                name: 'Employee Contract',
                list: '/employee-contract',
                edit: '/employee-contract/edit/:id',
                create: '/employee-contract/create',
                meta: {
                  label: 'Employee Contracts',
                  parent: 'Employees'
                }
              }, {
                name: 'Employee Group',
                list: '/employee-group',
                edit: '/employee-group/edit/:id',
                create: '/employee-group/create',
                meta: {
                  label: 'Employee Groups',
                  parent: 'Employees',
                  hide: true
                }
              }, {
                name: 'Employee Calendar',
                list: '/employee-calendar',
                edit: '/employee-calendar/edit/:id',
                create: '/employee-calendar/create',
                meta: {
                  label: 'Employee Calendars',
                  parent: 'Employees'
                }
              }, {
                identifier: 'employee-calendar-holiday',
                name: 'Employee Calendar Holiday',
                //list: '/employee-calendar-holiday',
                edit: '/employee-calendar-holiday/edit/:id',
                create: '/employee-calendar-holiday/create',
                meta: {
                  label: 'Employee Calendar Holidays'
                }
              }, {
                name: 'Employee Role',
                list: '/employee-role',
                edit: '/employee-role/edit/:id',
                create: '/employee-role/create',
                meta: {
                  label: 'Employee Roles',
                  parent: 'Employees',
                  hide: true
                }
              }, {
                name: 'User',
                list: '/user',
                edit: '/user/edit/:id',
                create: '/user/create',
                meta: {
                  hide: true
                }
              }, {
                name: 'System Role',
                list: '/system-role',
                create: '/system-role/create',
                edit: '/system-role/edit/:id',
                meta: {
                  label: 'System Roles',
                  hide: true
                }
              }, {
                name: 'Day Off',
                list: '/day-off',
                edit: '/day-off/edit/:id',
                create: '/day-off/create',
                meta: {
                  label: 'Day-Offs',
                  hide: true
                }
              }, {
                name: 'Day Off Type',
                list: '/day-off-type',
                edit: '/day-off-type/edit/:id',
                create: '/day-off-type/create',
                meta: {
                  label: 'Day-Offs Types',
                  hide: true
                }
              }, {
                name: 'Currency',
                list: '/currency',
                edit: '/currency/edit/:id',
                create: '/currency/create',
                meta: {
                  hide: true
                }
              }, {
                name: 'My Account',
                list: '/my-account',
                meta: {
                  label: 'My Account',
                  hide: true
                }
              }]} options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                projectId: '8Bz7ix-wNDa0f-XA9JLg'
              }}>
                  <>
                    <Outlet />
                    <UnsavedChangesNotifier />
                    <RefineKbar />
                  </>
                </Refine>
                <DevtoolsPanel />
              </DevtoolsProvider>
            </AntdApp>
          </ColorModeContextProvider>
        </RefineKbarProvider>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s(App, "OxHcXQU2dhJ3XHb2lPJyoEth/3A=", false, function () {
  return [useLoaderData];
});
_c = App;
export function links() {
  return [{
    rel: 'stylesheet',
    href: resetStyle
  }];
}
export const loader = async ({
  request
}) => {
  let profile;
  if (typeof authProvider.getIdentity === 'function') {
    profile = await authProvider.getIdentity(request);
  } else {
    console.error('getIdentity method is not defined in authProvider');
  }
  let permissions;
  if (typeof authProvider.getPermissions === 'function') {
    permissions = await authProvider.getPermissions(request);
  } else {
    console.error('getPermissions method is not defined in authProvider');
  }
  const to = new URL(request.url).searchParams.get('to');
  return json({
    profile,
    to,
    permissions
  });
};
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;